.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 1024px){
  .full-page {
    color: #F0EAE0;
    width: 80%;
    margin:auto;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px){
  .full-page {
    color: #F0EAE0;
    width: 50%;
    margin:auto;
    padding-bottom: 100px;
  }
}

@font-face {
  font-family: "MotelCaScript";
  src: url("./fonts/MotelCalifornia-Script.otf") format("opentype");
}

@font-face {
  font-family: "MotelCaReg";
  src: url("./fonts/MotelCalifornia-Regular.otf") format("opentype");
}

@font-face {
  font-family: "MotelCaRegTextured";
  src: url("./fonts/MotelCalifornia-RegularTextured.otf") format("opentype");
}

@font-face {
  font-family: "MotelCaIta";
  src: url("./fonts/MotelCalifornia-Italic.otf") format("opentype");
}

@font-face {
  font-family: "MotelCaItaTextured";
  src: url("./fonts/MotelCalifornia-ItalicTextured.otf") format("opentype");
}

.page-title {
  font-family: "MotelCaScript";
  font-size: 72px;
  padding-top: 50px;
  padding-bottom: 15px;
  text-align: center;
}

.section-title {
  font-family: "MotelCaRegTextured";
  font-size: 36px;
  padding-top: 30px;
  padding-bottom: 10px;
}

.subsection-title {
  font-family: "MotelCaItaTextured";
  font-size: 22px;
  padding-bottom: 5px;
  padding-top: 14px;
}

.drink-title {
  font-family: "MotelCaReg";
  font-size: 18px;
  color: #22292C;
}

.drink {
  padding-bottom: 10px;
}

.ingredients {
  font-family: "MotelCaIta";
  font-size: 14px;
  color: #22292C;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
